export default [
  {
    path: 'seasons',
    children: [
      {
        name: 'seasons',
        path: '',
        component: () => import('./SeasonsList.vue'),
        meta: { title: 'Seasons', guard: false }
      },
      {
        name: 'create-season',
        path: 'create',
        component: () => import('./SeasonForm.vue'),
        meta: { title: 'New Season' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-season',
        path: ':id/edit',
        component: () => import('./SeasonForm.vue'),
        meta: { title: 'Edit Season' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-season',
        path: ':id/view',
        component: () => import('./SeasonView.vue'),
        meta: {
          title: 'View Season'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
