import { defineStore } from 'pinia'
import { useApi } from '@/composables/useApi.js'
import { useUserStore } from '@/stores/user.js'

export const useDataStore = defineStore('data', {
  state: () => {
    return {
      types: {},
      clubs: [],
      listManagementClubs: [],
      allClubs: [],
      seasons: [],
      rounds: [],
      competitions: [],
      activeCompetitions: [],
      venues: [],
      contractTemplates: [],
    }
  },
  getters: {
    getSeasonName(state) {
      return (id) => {
        return state.seasons.find(season => season.value === id)?.label
      }
    },
    getRoundName(state) {
      return (id) => {
        return state.rounds.find(round => round.value === id)?.label
      }
    }
  },
  actions: {
    async clearCache() {
      this.types = {}
      this.clubs = []
      this.listManagementClubs = []
      this.allClubs = []
      this.seasons = []
      this.rounds = []
      this.competitions = []
      this.activeCompetitions = []
      this.venues = []
      this.contractTemplates = []

      await this.getClubs()
      await this.getAllClubs()
      await this.getListManagementClubs()
      await this.getSeasons()
      await this.getRounds()
      await this.getCompetitions()
      await this.getActiveCompetitions()
      await this.getVenues()
      await this.getContractTemplates()
    },
    async getTypeOptions(optionGroup, disableAllOption = false, label = 'name', value = 'id', extras = []) {
      const { api } = useApi()

      if (this.types[optionGroup] && this.types[optionGroup].length) {
        return this.types[optionGroup]
      }

      try {
        const { data } = await api.get('/types', {
          params: {
            filters: {
              group: optionGroup,
              active: true
            },
            orderBy: 'ordering:asc,name:asc'
          }
        })

        let options = data.map((type) => {
          let returnOption = {
            label: type[label],
            value: type[value],
          }

          extras.forEach((extra) => {
            if (extra.includes('.')) {
              const [parent, child] = extra.split('.')
              returnOption[child] = type[parent][child]

              if (returnOption[child] === undefined) {
                returnOption[child] = false
              }

              return
            }

            returnOption[extra] = type[extra]
          })

          return returnOption
        })

        if (!disableAllOption) {
          options.unshift({
            label: 'All',
            value: null
          })
        }

        if (options.length) {
          this.types[optionGroup] = options
        }

        return options
      } catch (e) {
        console.log(e)
      }
    },
    async getAllClubs() {
      if (this.allClubs.length) {
        return this.allClubs
      }

      const { api } = useApi()

      try {
        const { data } = await api.get('/clubs', {
          params: {
            orderBy: 'name:asc',
            response: 'options',
          }
        })

        if (data.length) {
          this.allClubs = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getListManagementClubs() {
      const { api } = useApi()

      try {
        const { data } = await api.get('/clubs', {
          params: {
            orderBy: 'name:asc',
            response: 'options',
            filters: {
              active: true,
              list_management: true,
            }
          }
        })

        if (data.length) {
          this.listManagementClubs = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getClubs() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/clubs', {
          params: {
            orderBy: 'name:asc',
            response: 'options',
            filters: {
              active: true,
            }
          }
        })

        if (data.length) {
          this.clubs = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getSeasons() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/seasons', {
          params: {
            orderBy: 'name:desc',
            response: 'options',
          }
        })

        if (data.length) {
          this.seasons = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getRounds() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/rounds', {
          params: {
            orderBy: 'ordering:asc',
            response: 'options',
          }
        })

        if (data.length) {
          this.rounds = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getActiveCompetitions() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/competitions', {
          params: {
            orderBy: 'ordering:asc',
            response: 'options',
            filters: {
              active: true
            }
          }
        })

        if (data.length) {
          this.activeCompetitions = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getCompetitions() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/competitions', {
          params: {
            orderBy: 'ordering:asc',
            response: 'options',
          }
        })

        if (data.length) {
          this.competitions = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getVenues() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/venues',  {
          params: {
            filters: {
              active: true
            },
            orderColumn: 'name',
            response: 'options',
          }
        })

        if (data.length) {
          this.venues = data
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
    async getContractTemplates() {
      const { api } = useApi()
      const userStore = useUserStore()

      if (!userStore.currentTenant) {
        return []
      }

      try {
        const { data } = await api.get('/contract-templates',  {
          params: {
            filters: {
              active: true
            },
            orderColumn: 'name',
          }
        })

        if (data.length) {
          this.contractTemplates = data.map((template) => {
            return {
              label: template.name,
              value: template.id,
              upload_only: template.upload_only,
            }
          })
        }

        return data
      } catch (e) {
        console.log(e)
      }
    },
  },
  persist: {
    key: `${import.meta.env.VITE_STORE_PREFIX}-data`,
  },
})
