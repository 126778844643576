import bannersRoutes from '@/views/website/banners/routes.js'
import publicationRoutes from '@/views/website/publications/routes.js'

export default [
  {
    path: 'website',
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      ...bannersRoutes,
      ...publicationRoutes,
    ]
  },
]
