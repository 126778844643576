import attendanceRoutes from './attendance/routes'
import concussionRoutes from './concussions/routes'
import contractsRoutes from './contracts/routes'
import formsRoutes from './forms/routes'
import injuriesRoutes from './injuries/routes'
import listManagementRoutes from './list-management/routes'
import matchResultsRoutes from './match-results/routes'
import playerPaymentsRoutes from './player-payments/routes'
import selectionRoutes from './selection/routes'
import tribunalRoutes from './tribunal/routes'
import clubVotingRoutes from './club-voting/routes'
import votingRoutes from './voting/routes'

export default [
  {
    path: 'operations',
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      ...attendanceRoutes,
      ...concussionRoutes,
      ...contractsRoutes,
      ...formsRoutes,
      ...injuriesRoutes,
      ...listManagementRoutes,
      ...matchResultsRoutes,
      ...playerPaymentsRoutes,
      ...selectionRoutes,
      ...tribunalRoutes,
      ...clubVotingRoutes,
      ...votingRoutes,
    ]
  },
]
