import duplicatesRoutes from './duplicates/routes'
import permissionsRoutes from './permissions/routes'
import sectionsRoutes from './sections/routes'
import tenantsRoutes from './tenants/routes'

export default [
  {
    path: 'admin',
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      ...duplicatesRoutes,
      ...permissionsRoutes,
      ...sectionsRoutes,
      ...tenantsRoutes,
    ]
  },
]
