export default [
  {
    path: 'player-payments',
    children: [
      {
        name: 'player-payments',
        path: '',
        component: () => import('./PlayerPayments.vue'),
        meta: { title: 'Player Payments', guard: false }
      },
      {
        path: 'exclusions',
        children: [
          {
            name: 'exclusions',
            path: '',
            component: () => import('./Exclusions.vue'),
            meta: { title: 'Player Payment Exclusions' },
          },
          {
            name: 'create-exclusion',
            path: 'create',
            component: () => import('./PlayerPaymentExclusionForm.vue'),
            meta: { title: 'Create Player Payment Exclusion' },
            props: {
              type: 'New'
            }
          },
          {
            name: 'edit-exclusion',
            path: ':id/edit',
            component: () => import('./PlayerPaymentExclusionForm.vue'),
            meta: { title: 'Edit Player Payment Exclusion' },
            props: (route) => ({
              type: 'Edit',
              id: route.params.id
            })
          },
          {
            name: 'view-exclusion',
            path: ':id/view',
            component: () => import('./PlayerPaymentExclusionView.vue'),
            meta: {
              title: 'View Player Payment Exclusion'
            },
            props: (route) => ({
              id: route.params.id
            })
          }
        ]
      },
    ]
  },
]
