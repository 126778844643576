import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue"

import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-date-picker.css'
import './css/daterangepicker.custom.css'
import './css/ckeditor.custom.css'

import '@formkit/themes/genesis'
import '@formkit/pro/genesis'
import './css/index.css'
import './css/formkit.custom.css'
import './css/jsoneditor.custom.css'
import 'vue3-toastify/dist/index.css'
import 'video.js/dist/video-js.css'

import config from '../formkit.config.js'

import { plugin, defaultConfig } from '@formkit/vue'
import Vue3Toastify from 'vue3-toastify'
import vClickOutside from 'click-outside-vue3'

import VueApexCharts from 'vue3-apexcharts'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: "https://c959d5784d0cea0cd4b17c5c071fdf50@o1127129.ingest.sentry.io/4506838644097024",
    integrations: [],
    environment: import.meta.env.MODE,
  })
}

app.use(router)
app.use(pinia)
app.use(plugin, defaultConfig(config))
app.use(Vue3Toastify, {
  autoClose: 3000,
  theme: 'colored',
  position: 'bottom-right',
  limit: 5,
  clearOnUrlChange: false,
})
app.use(vClickOutside)
app.use(VueApexCharts)

app.config.performance = true

app.mount('#app')
