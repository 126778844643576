import { ElDatePicker } from 'element-plus'
import { defineComponent, h, computed } from 'vue'

export default defineComponent({
  props: {
    context: {
      required: true,
      type: Object,
    }
  },
  setup(props) {
    if (props.context.classes.inner === 'formkit-inner') {
      // eslint-disable-next-line vue/no-mutating-props
      props.context.classes.inner = ''
    }

    const value = computed({
      get() {
        return props.context.value
      },
      set(val) {
        props.context.node.input(val)
      }
    })
    return () => {
      return h(ElDatePicker, {
        valueFormat: "DD-MM-YYYY",
        type: "daterange",
        modelValue: value.value,
        "onUpdate:modelValue": (val) => {
          value.value = val
        },
        onBlur() {
          props.context.handlers.blur()
        },
        ...props.context.attrs
      })
    }
  }
})
