export default [
  {
    path: 'selection',
    children: [
      {
        name: 'selection',
        path: '',
        component: () => import('./SelectionList.vue'),
        meta: { title: 'Selection', guard: false }
      },
      {
        name: 'edit-selection',
        path: ':id/edit',
        component: () => import('./SelectionForm.vue'),
        meta: { title: 'Edit Selection' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
    ]
  },
]
