export default [
  {
    path: 'reports',
    children: [
      {
        name: 'reports',
        path: '',
        component: () => import('./ReportsDashboard.vue'),
        meta: { title: 'Reports' }
      },
    ]
  },
]
