export default [
  {
    path: 'duplicates',
    children: [
      {
        name: 'duplicate-players',
        path: 'players',
        component: () => import('./DuplicatePlayers.vue'),
        meta: { title: 'Duplicate Players', guard: false }
      },
    ]
  },
]
