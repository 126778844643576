export default [
  {
    path: 'matches',
    children: [
      {
        name: 'matches',
        path: '',
        component: () => import('./MatchesList.vue'),
        meta: { title: 'Matches', guard: false }
      },
      {
        name: 'create-match',
        path: 'create',
        component: () => import('./MatchForm.vue'),
        meta: { title: 'New Match' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-match',
        path: ':id/edit',
        component: () => import('./MatchForm.vue'),
        meta: { title: 'Edit Match' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-match',
        path: ':id/view',
        component: () => import('./MatchView.vue'),
        meta: {
          title: 'View Match'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
