import groupsRoutes from './groups/routes'
import notificationsRoutes from './notifications/routes'
import templatesRoutes from './templates/routes'
import typesRoutes from './types/routes'
import usersRoutes from './users/routes'

export default [
  {
    path: 'configuration',
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      ...groupsRoutes,
      ...notificationsRoutes,
      ...templatesRoutes,
      ...typesRoutes,
      ...usersRoutes,
    ]
  },
]
