export default [
  {
    path: 'users',
    children: [
      {
        name: 'users',
        path: '',
        component: () => import('./UsersList.vue'),
        meta: { title: 'Users' }
      },
      {
        name: 'create-user',
        path: 'create',
        component: () => import('./UserForm.vue'),
        meta: {
          title: 'New User'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-user',
        path: ':id/edit',
        component: () => import('./UserForm.vue'),
        meta: { title: 'Edit User' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-user',
        path: ':id/view',
        component: () => import('./UserView.vue'),
        meta: { title: 'View User' },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
