export default [
  {
    path: 'list-management',
    children: [
      {
        name: 'list-management',
        path: '',
        component: () => import('./ListManagement.vue'),
        meta: { title: 'List Management', guard: false }
      },
      {
        name: 'list-change',
        path: 'list-change',
        component: () => import('./ListChangeForm.vue'),
        meta: { title: 'List Change' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'requests',
        path: 'requests',
        component: () => import('./ListRequests.vue'),
        meta: { title: 'List Requests/Changes' },
      },
      {
        name: 'list-management-edit-players',
        path: 'players/:id/edit',
        component: () => import('@/views/players/PlayerForm.vue'),
        meta: { title: 'Edit Player' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id,
          returnPath: '/operations/list-management'
        })
      },
      {
        name: 'list-management-view-players',
        path: 'players/:id/view',
        component: () => import('@/views/players/PlayerView.vue'),
        meta: {
          title: 'View Player'
        },
        props: (route) => ({
          id: route.params.id,
          returnPath: '/operations/list-management'
        })
      },
    ]
  },
]
