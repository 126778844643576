import { defineStore } from 'pinia'

export const useFiltersStore = defineStore('filters', {
  state: () => {
    return {
      filters: {},
      orderByColumn: {},
      orderDirection: {},
      perPage: {},
      listManagementClub: null,
      listManagementList: null,
    }
  },
  actions: {
    async clearCache() {
      this.filters = {}
      this.orderByColumn = {}
      this.orderDirection = {}
      this.perPage = {}
      this.listManagementClub = null
      this.listManagementList = null
    },
  },
  persist: {
    key: `${import.meta.env.VITE_STORE_PREFIX}-filters`,
  },
})
