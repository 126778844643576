export default [
  {
    path: 'venues',
    children: [
      {
        name: 'venues',
        path: '',
        component: () => import('./VenuesList.vue'),
        meta: { title: 'Venues', guard: false }
      },
      {
        name: 'create-venue',
        path: 'create',
        component: () => import('./VenueForm.vue'),
        meta: { title: 'New Venue' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-venue',
        path: ':id/edit',
        component: () => import('./VenueForm.vue'),
        meta: { title: 'Edit Venue' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-venue',
        path: ':id/view',
        component: () => import('./VenueView.vue'),
        meta: {
          title: 'View Venue'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
