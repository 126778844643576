import { useUserStore } from '../stores/user'

export function auth(to) {
  const userStore = useUserStore()

  if (!userStore.token) {
    localStorage.setItem('redirect', to.path)
    return { name: 'login', query: { redirected: true } }
  }
}
