export default [
  {
    path: 'umpire-portal',
    children: [
      {
        name: 'umpire-portal',
        path: '',
        component: () => import('./UmpirePortalDashboard.vue'),
        meta: {
          title: 'Dashboard - Umpire Portal',
        },
      },
      {
        name: 'umpire-portal-overview',
        path: ':matchId/overview',
        component: () => import('./Overview.vue'),
        meta: {
          title: 'Match Overview - Umpire Portal',
        },
        props: (route) => ({
          matchId: route.params.matchId,
        })
      },
      {
        name: 'create-umpire-portal-reports',
        path: ':matchId/report',
        component: () => import('./MatchDayReportForm.vue'),
        meta: {
          title: 'Match Day Report - Umpire Portal',
        },
        props: (route) => ({
          type: 'New',
          matchId: route.params.matchId,
        })
      },
      {
        name: 'edit-umpire-portal-reports',
        path: ':matchId/report/:reportId',
        component: () => import('./MatchDayReportForm.vue'),
        meta: {
          title: 'Match Day Report - Umpire Portal',
        },
        props: (route) => ({
          type: 'Edit',
          matchId: route.params.matchId,
          id: route.params.reportId,
        })
      },
      {
        name: 'umpire-portal-votes',
        path: ':matchId/voting',
        component: () => import('./VotingForm.vue'),
        meta: {
          title: 'Voting - Umpire Portal',
        },
        props: (route) => ({
          type: 'New',
          matchId: route.params.matchId,
        })
      },
      {
        name: 'umpire-portal-tribunal',
        path: ':matchId/tribunal',
        component: () => import('./UmpirePortalTribunalForm.vue'),
        meta: {
          title: 'Tribunal - Umpire Portal',
        },
        props: (route) => ({
          type: 'New',
          matchId: route.params.matchId,
        })
      },
    ]
  },
]
