export default [
  {
    path: 'tenants',
    children: [
      {
        name: 'tenants',
        path: '',
        component: () => import('./TenantsList.vue'),
        meta: {
          title: 'Users'
        }
      },
      {
        name: 'create-tenant',
        path: 'create',
        component: () => import('./TenantForm.vue'),
        meta: {
          title: 'New Tenant'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-tenant',
        path: ':id/edit',
        component: () => import('./TenantForm.vue'),
        meta: {
          title: 'Edit Tenant'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-tenant',
        path: ':id/view',
        component: () => import('./TenantView.vue'),
        meta: { title: 'View Tenant' },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
