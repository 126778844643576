import { arrowDown, arrowUp, check, circle, close, down, fileDoc, spinner, star, trash, } from "@formkit/icons"
import { generateClasses } from '@formkit/themes'
import theme from './formkit.theme.js'
import { createProPlugin, inputs } from '@formkit/pro'
import { createElementPlugin } from '@/components/form/element-plus/NifteeFormKitPlugin.js'

const pro = createProPlugin('fk-8e479534398', inputs)

const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist']

function addAsteriskPlugin (node) {
  if (['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(node.props.type)) return

  node.on('created', () => {
    const legendOrLabel = legends.includes(`${node.props.type}${node.props.options ? '_multi' : ''}`) ? 'legend' : 'label'

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${node.props.options ? '_multi' : ''}_add_asterisk`
    }

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: ['$label', {
          $el: 'span',
          if: '$state.required',
          attrs: {
            class: 'text-red-500 pl-0.5',
          },
          children: ['*']
        }]
      }

      return schemaFn(sectionsSchema)
    }
  })
}

function scrollToErrors(node) {
  if (node.props.type === 'form') {
    const scrollTo = (node) => {
      const el = document.getElementById(node.props.id)
      if (el) {
        el.scrollIntoView()
      }
    }

    const scrollToErrors = () => {
      node.walk(child => {
        // Check if this child has errors
        if (child.ledger.value('blocking') || child.ledger.value('errors')) {
          // We found an input with validation errors
          scrollTo(child)
          // Stop searching
          return false
        }
      }, true)
    }

    node.props.onSubmitInvalid = scrollToErrors
    node.on('unsettled:errors', scrollToErrors)
  }

  return false
}

const config = {
  config: {
    classes: generateClasses(theme)
  },
  icons: {
    arrowDown,
    arrowUp,
    close,
    checkboxDecorator: check,
    fileItem: fileDoc,
    fileRemove: close,
    noFiles: fileDoc,
    radioDecorator: circle,
    select: down,
    spinner,
    star,
    trash,
  },
  plugins: [
    pro,
    addAsteriskPlugin,
    scrollToErrors,
    createElementPlugin()
  ]
}

export default config
