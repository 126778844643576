export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: '$reset pb-1',
    help: 'text-xs text-gray-500 font-sans',
    inner: 'formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none font-sans formkit-invalid:ring-red-500 ring-1 ring-gray-400 focus-within:ring-gray-500 focus:shadow-none',
    input: 'appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none font-sans text-base formkit-invalid:ring-red-500',
    label: '$reset block mb-2 text-slate-800 pb-0 text-sm',
    legend: '$reset block mb-2 text-slate-800 pb-0 text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs font-sans text-xs',
    messages: 'list-none p-0 mt-0 mb-0 font-sans text-base',
    outer: '$reset font-sans text-base mb-0',
    prefixIcon: 'w-[10%] h-[40px] flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    wrapper: 'max-w-full font-sans text-base'
  },
  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator: 'block relative h-[15px] w-[15px] rounded bg-white bg-gradient-to-b from-transparent to-gray-200 ring-1 ring-gray-400 peer-checked:ring-gray-500 text-transparent peer-checked:text-default-500',
    decoratorIcon: 'flex p-[1px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-default-500',
    help: 'mb-2 mt-1.5',
    input: 'fixed left-[999em] w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label: '$reset text-sm text-gray-800 select-none',
    wrapper: 'flex items-center mb-1 cursor-pointer',
    inner: '$reset formkit-inner formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none font-sans formkit-invalid:ring-red-500 ring-gray-400 focus-within:ring-gray-500 focus:shadow-none'
  },
  'family:button': {
    input: '$reset inline-flex items-center bg-default-500 text-white text-base font-normal py-2 px-4 rounded family-button',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper: 'my-2 w-full drop-shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    inner: 'relative flex ring-1 ring-gray-400 focus-within:ring-gray-500 focus-within:ring-1 rounded mb-1 formkit-disabled:focus-within:ring-gray-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white drop-shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem: 'text-sm pl-2 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 data-[is-active="true"]:aria-selected:bg-blue-600 aria-selected:bg-blue-600 aria-selected:text-white',
    loaderIcon: 'ml-auto',
    loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'text-gray-500',
    placeholder: 'p-2.5 text-gray-400',
    selector: 'flex w-full justify-between items-center [&u]',
    selectedIcon: 'hidden',
    selectIcon: 'flex box-content px-2 self-stretch grow-0 shrink-0',
  },
  'family:text': {
    inner: 'flex items-center ring-1 ring-gray-400 focus-within:ring-gray-500 formkit-invalid:ring-red-500 focus-within:ring-1 [&>label:first-child]:focus-within:text-blue-500 mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-gray-800 placeholder-gray-400',
  },
  // Specific styles apply only to a given input type
  color: {
    inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
    input: '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none h-[40px]',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto'
  },
  file: {
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative cursor-pointer formkit-multiple:[&>button]:absolute',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
    noFilesIcon: 'w-4 mr-2'
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
    options: 'space-y-4',
    optionHelp: 'text-xs pt-2'
  },
  range: {
    inner: '$reset flex items-center',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto'
  },
  select: {
    inner: 'flex relative items-center rounded mb-1 ring-1 ring-gray-400 focus-within:ring-gray-500 focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
    input: 'w-full pl-3 pr-8 py-2 border-none text-base text-gray-800 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none items-center absolute right-0',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-800'
  },
  textarea: {
    // inner: 'flex rounded mb-1 ring-1 ring-gray-400 focus-within:ring-gray-500 [&>label:first-child]:focus-within:text-blue-500',
    inner: 'rounded ring-1 ring-gray-400 focus-within:ring-gray-500 focus-within:ring-1 formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none font-sans formkit-invalid:ring-red-500',
    input: '$reset formkit-input appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none font-sans text-base formkit-invalid:ring-red-500 w-full px-3 py-2 border-none text-gray-800 placeholder-gray-400 ring-gray-400 focus-within:ring-gray-500',
  },

  // PRO input styles
  autocomplete: {
    closeIcon: 'block grow-0 shrink-0 w-3 mr-3.5',
    inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0',
    option: 'grow text-ellipsis',
    selection: 'static flex left-0 top-0 right-0 bottom-0 mt-0 mb-2 rounded bg-gray-100',
  },
  mask: {
    inner: 'flex items-center ring-1 ring-gray-400 focus-within:ring-gray-500 focus-within:ring-1 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-gray-800 placeholder-gray-400',
  },
  rating: {
    inner: 'relative flex items-center w-[8em] formkit-disabled:bg-transparent',
    itemsWrapper: 'w-full',
    onItems: 'text-yellow-400',
    onItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
    offItems: 'text-gray-500',
    offItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none'
  },
  repeater: {
    content: 'grow p-3 flex flex-col align-center',
    controlLabel: 'absolute opacity-0 pointer-events-none',
    controls: 'flex flex-col items-center justify-center bg-gray-100 p-3',
    downControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    fieldset: 'py-4 px-5',
    help: 'mb-2 mt-1.5',
    item: 'flex w-full mb-1 rounded border border-gray-200',
    moveDownIcon: 'block w-3 my-1',
    moveUpIcon: 'block w-3 my-1',
    removeControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    removeIcon: 'block w-5 my-1',
    upControl: 'hover:text-blue-500 disabled:hover:text-inherit disabled:opacity-25',
    inner: '$reset'
  },
  taglist: {
    input: 'px-1 py-1 w-[0%] grow',
    removeSelection: 'w-2.5 mx-1 self-center text-black leading-none',
    tag: 'flex items-center my-1 p-1 bg-gray-200 text-xs rounded-full',
    tagWrapper: 'mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-blue-500 [&>div>button]:focus:text-white',
    tagLabel: 'pl-2 pr-1',
    tags: 'flex items-center flex-wrap w-full py-1.5 px-2',
  },
  toggle: {
    altLabel: 'block w-full mb-2 text-slate-800 pb-0 text-sm font-sans',
    inner: '$reset inline-block mr-2',
    input: 'peer fixed left-[999em] opacity-0 pointer-events-none',
    innerLabel: 'text-[13px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
    track: 'cursor-pointer p-0.5 min-w-[100px] relative rounded-full transition-all bg-gray-400 peer-checked:bg-green-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'font-bold text-sm',
    wrapper: 'flex flex-wrap items-center mb-1'
  }
}
