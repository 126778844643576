export default [
  {
    path: 'groups',
    children: [
      {
        name: 'groups',
        path: '',
        component: () => import('./GroupsList.vue'),
        meta: {
          title: 'Groups',
        }
      },
      {
        name: 'create-group',
        path: 'create',
        component: () => import('./GroupForm.vue'),
        meta: {
          title: 'New Group'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-group',
        path: ':id/edit',
        component: () => import('./GroupForm.vue'),
        meta: {
          title: 'Edit Group'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-group',
        path: ':id/view',
        component: () => import('./GroupView.vue'),
        meta: {
          title: 'View Group'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
