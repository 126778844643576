import { createInput } from '@formkit/vue'

import DateRangePicker from './DateRangePicker'

export const createElementPlugin = () => {
  return (node) => {
    let type = node.props.type + ''
    type = type.replace(/[A-Z]/g, (m) => "-" + m.toLowerCase())

    let create = createInput

    switch (type) {
    case "el-date-range-picker":
      return node.define(create(DateRangePicker))
    default:
      return false
    }
  }
}
