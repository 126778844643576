export default [
  {
    path: 'types',
    children: [
      {
        name: 'types',
        path: '',
        component: () => import('./TypeList.vue'),
        meta: {
          title: 'Types'
        }
      },
      {
        name: 'create-type',
        path: 'create',
        component: () => import('./TypeForm.vue'),
        meta: {
          title: 'New Type'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'create-type-group',
        path: 'create-group',
        component: () => import('./TypeGroupForm.vue'),
        meta: {
          title: 'New Type Group'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-type',
        path: ':id/edit',
        component: () => import('./TypeForm.vue'),
        meta: {
          title: 'Edit Type'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'order-type',
        path: 'ordering',
        component: () => import('./TypeOrdering.vue'),
        meta: { title: 'Order Types' }
      }
    ]
  },
]
