export default [
  {
    path: 'stats-portal',
    children: [
      {
        name: 'stats-portal',
        path: '',
        component: () => import('./StatsPortalDashboard.vue'),
        meta: {
          title: 'Dashboard - Stats Portal',
          sidebar: false,
        },
      },
      {
        name: 'stats-portal-selection',
        path: ':matchId/selection',
        component: () => import('./StatsPortalSelection.vue'),
        meta: {
          title: 'Team Selection - Stats Portal',
          sidebar: false,
        },
        props: (route) => ({
          matchId: route.params.matchId,
        })
      },
      {
        name: 'stats-portal-capture',
        path: ':matchId/capture',
        component: () => import('./StatsPortalCapture.vue'),
        meta: {
          title: 'Stats Capture - Stats Portal',
          sidebar: false,
        },
        props: (route) => ({
          matchId: route.params.matchId,
        })
      },
    ]
  },
]
