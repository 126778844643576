export default [
  {
    path: 'concussions',
    children: [
      {
        name: 'concussions',
        path: '',
        component: () => import('./ConcussionsList.vue'),
        meta: { title: 'Concussions', guard: false }
      },
      {
        name: 'create-concussion',
        path: 'create',
        component: () => import('./ConcussionForm.vue'),
        meta: { title: 'New Concussion' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-concussion',
        path: ':id/edit',
        component: () => import('./ConcussionForm.vue'),
        meta: { title: 'Edit Concussion' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-concussion',
        path: ':id/view',
        component: () => import('./ConcussionView.vue'),
        meta: {
          title: 'View Concussion'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
