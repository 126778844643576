export default [
  {
    path: 'rounds',
    children: [
      {
        name: 'rounds',
        path: '',
        component: () => import('./RoundsList.vue'),
        meta: { title: 'Rounds', guard: false }
      },
      {
        name: 'create-round',
        path: 'create',
        component: () => import('./RoundForm.vue'),
        meta: { title: 'New Round' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-round',
        path: ':id/edit',
        component: () => import('./RoundForm.vue'),
        meta: { title: 'Edit Round' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-round',
        path: ':id/view',
        component: () => import('./RoundView.vue'),
        meta: {
          title: 'View Round'
        },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'order-rounds',
        path: 'ordering',
        component: () => import('./RoundOrdering.vue'),
        meta: { title: 'Order Rounds' }
      }
    ]
  },
]
