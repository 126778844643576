import { createRouter, createWebHistory } from 'vue-router'
import { auth } from './guards'
import { useGlobalStore } from '@/stores/global.js'
import { useUserStore } from '@/stores/user.js'
import adminRoutes from '@/views/admin/routes.js'
import coachesRoutes from '@/views/coaches/routes.js'
import configurationRoutes from '@/views/configuration/routes.js'
import coursesRoutes from '@/views/courses/routes.js'
import managementRoutes from '@/views/management/routes.js'
import matchesRoutes from '@/views/matches/routes.js'
import operationsRoutes from '@/views/operations/routes.js'
import playerRoutes from '@/views/players/routes.js'
import reportsRoutes from '@/views/reports/routes.js'
import toolsRoutes from '@/views/tools/routes.js'
import umpiresRoutes from '@/views/umpires/routes.js'
import websiteRoutes from '@/views/website/routes.js'
import umpirePortalRoutes from '@/views/umpire-portal/routes.js'
import statsPortalRoutes from '@/views/stats-portal/routes.js'
import learningPortalRoutes from '@/views/learning-portal/routes.js'

const router = createRouter({
  history: createWebHistory(
    import.meta.env.BASE_URL
  ),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import ('@/views/Login.vue'),
      meta: { title: 'Login' }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/ResetPassword.vue'),
      meta: { title: 'Reset Password' }
    },
    {
      path: '/auth/:provider/callback',
      name: 'oauth',
      component: () => import ('@/views/OAuth.vue'),
      meta: { title: 'SSO Authentication' },
      props: (route) => ({ provider: route.params.provider })
    },
    {
      path: '/sign/:id/:valueId',
      name: 'public-contract-sign',
      component: () => import ('@/views/operations/contracts/PublicContractView.vue'),
      meta: { title: 'Sign Contract' }
    },
    {
      path: '/contract/:id',
      name: 'public-contract-view',
      component: () => import ('@/views/operations/contracts/PublicContractView.vue'),
      meta: { title: 'View Contract' }
    },
    {
      path: '/',
      component: () => import('@/views/Default.vue'),
      redirect: '/dashboard',
      beforeEnter: [auth],
      children: [
        {
          path: 'dashboard',
          children: [
            {
              name: 'dashboard',
              path: '',
              component: () => import('../views/Dashboard.vue'),
              meta: { title: 'Dashboard' }
            }
          ]
        },
        {
          path: 'settings',
          children: [
            {
              name: 'settings',
              path: '',
              component: () => import('../views/Settings.vue'),
              meta: { title: 'Settings' }
            }
          ]
        },
        ...adminRoutes,
        ...coachesRoutes,
        ...configurationRoutes,
        ...coursesRoutes,
        ...matchesRoutes,
        ...managementRoutes,
        ...operationsRoutes,
        ...playerRoutes,
        ...reportsRoutes,
        ...toolsRoutes,
        ...umpiresRoutes,
        ...websiteRoutes,
        ...umpirePortalRoutes,
        ...statsPortalRoutes,
        ...learningPortalRoutes,
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const globalStore = useGlobalStore()
  const userStore = useUserStore()

  document.title = to.meta.title ? to.meta.title + ' | ' + import.meta.env.VITE_NAME : import.meta.env.VITE_NAME

  if (globalStore.redirect) {
    let redirect = globalStore.redirect
    globalStore.redirect = null
    next(redirect)
  }

  if (window.Maker) {
    if (['stats-portal-capture'].includes(to.name)) {
      window.Maker.hide()
    } else {
      window.Maker.show()
    }
  }

  if (['reset-password', 'register', 'invite', 'join', 'oauth', 'public-contract-sign', 'public-contract-view'].includes(to.name)) {
    next()
  } else if (!userStore.token && to.name !== 'login') {
    next('login')
  } else if (userStore.token && ['register', 'invite', 'login'].includes(to.name)) {
    next('dashboard')
  } else {
    next()
  }
})

router.onError((error) => {
  const chunkLoadFailedRegex = /Loading chunk \d+ failed|Failed to fetch dynamically imported module/

  if (chunkLoadFailedRegex.test(error.message)) {
    console.error('Dynamic import failed. Reloading...')
    window.location.reload()
  }
})

export default router
