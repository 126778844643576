export default [
  {
    path: 'courses',
    children: [
      {
        name: 'courses',
        path: '',
        component: () => import('./CoursesList.vue'),
        meta: {
          title: 'Courses'
        }
      },
      {
        name: 'create-course',
        path: 'create',
        component: () => import('./CourseForm.vue'),
        meta: {
          title: 'New Course'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-course',
        path: ':id/edit',
        component: () => import('./CourseForm.vue'),
        meta: {
          title: 'Edit Course'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-course',
        path: ':id/view',
        component: () => import('./CourseView.vue'),
        meta: { title: 'View Course' },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'create-course-module',
        path: ':courseId/modules/create',
        component: () => import('./modules/CourseModuleForm.vue'),
        meta: {
          title: 'Create Course Module'
        },
        props: (route) => ({
          type: 'New',
          courseId: route.params.courseId,
        })
      },
      {
        name: 'edit-course-module',
        path: ':courseId/modules/:id/edit',
        component: () => import('./modules/CourseModuleForm.vue'),
        meta: {
          title: 'Edit Course Module'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id,
          courseId: route.params.courseId,
        })
      },
      {
        name: 'view-course-module',
        path: ':courseId/modules/:id/view',
        component: () => import('./modules/CourseModuleView.vue'),
        meta: {
          title: 'View Module'
        },
        props: (route) => ({
          id: route.params.id,
          courseId: route.params.courseId,
        })
      },
      {
        name: 'view-course-module-question',
        path: ':courseId/modules/:moduleId/questions/:id/view',
        component: () => import('./modules/questions/QuestionView.vue'),
        meta: {
          title: 'View Question'
        },
        props: (route) => ({
          id: route.params.id,
          courseId: route.params.courseId,
          moduleId: route.params.moduleId,
        })
      },
    ]
  },
]
