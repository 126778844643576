export default [
  {
    path: 'banners',
    children: [
      {
        name: 'banners',
        path: '',
        component: () => import('./BannerList.vue'),
        meta: { title: 'Banners' }
      },
      {
        name: 'create-banner',
        path: 'create',
        component: () => import('./BannerForm.vue'),
        meta: {
          title: 'New Banner'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-banner',
        path: ':id/edit',
        component: () => import('./BannerForm.vue'),
        meta: {
          title: 'Edit Banner'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-banner',
        path: ':id/view',
        component: () => import('./BannerView.vue'),
        meta: { title: 'View Banner' },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'order-banner',
        path: 'ordering',
        component: () => import('./BannerOrdering.vue'),
        meta: { title: 'Order Banners' }
      }
    ]
  },
]
