export default [
  {
    path: 'templates',
    children: [
      {
        name: 'templates',
        path: '',
        component: () => import('./ContractTemplatesList.vue'),
        meta: { title: 'Contract Templates', guard: false }
      },
      {
        name: 'create-contract-template',
        path: 'create',
        component: () => import('./ContractTemplateForm.vue'),
        meta: { title: 'New Contract Template' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-contract-template',
        path: ':id/edit',
        component: () => import('./ContractTemplateForm.vue'),
        meta: { title: 'Edit Contract Template' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'build-contract-template',
        path: ':id/build',
        component: () => import('./ContractBuilder.vue'),
        meta: { title: 'Build Contract Template' },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'view-contract-template',
        path: ':id/view',
        component: () => import('./ContractTemplateView.vue'),
        meta: {
          title: 'View Contract Template'
        },
        props: (route) => ({
          id: route.params.id
        })
      },
    ]
  },
]
