export default [
  {
    path: 'match-results',
    children: [
      {
        name: 'match-results',
        path: '',
        component: () => import('./MatchResultList.vue'),
        meta: { title: 'Matches', guard: false }
      },
      {
        name: 'edit-match-result',
        path: ':id/edit',
        component: () => import('./MatchResultForm.vue'),
        meta: { title: 'Edit Match' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
    ]
  },
]
