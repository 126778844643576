export default [
  {
    path: 'umpires',
    children: [
      {
        name: 'umpires',
        path: '',
        component: () => import('./UmpiresList.vue'),
        meta: { title: 'Umpires', guard: false }
      },
      {
        name: 'create-umpire',
        path: 'create',
        component: () => import('./UmpireForm.vue'),
        meta: { title: 'New Umpire' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-umpire',
        path: ':id/edit',
        component: () => import('./UmpireForm.vue'),
        meta: { title: 'Edit Umpire' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-umpire',
        path: ':id/view',
        component: () => import('./UmpireView.vue'),
        meta: {
          title: 'View Umpire'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
