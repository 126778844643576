export default [
  {
    path: 'club-votes',
    children: [
      {
        name: 'club-votes',
        path: '',
        component: () => import('./ClubVotingList.vue'),
        meta: { title: 'Matches', guard: false }
      },
      {
        name: 'club-votes-coaches-votes',
        path: ':id/coaches-votes',
        component: () => import('./CoachesVotesForm.vue'),
        meta: { title: 'Coaches Votes' },
        props: (route) => ({
          type: 'New',
          id: route.params.id
        })
      },
    ]
  },
]
