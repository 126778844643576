import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { useDataStore } from '@/stores/data.js'
import { useFiltersStore } from '@/stores/filters.js'
import { toast } from 'vue3-toastify'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      sidebarCollapsed: false,
      showPageLoader: false,
      redirect: null,
      theme: 'light',
      openSidebarMenus: [],
      showTenantModal: false,
      showClubModal: false,
      clearingCache: false
    }
  },
  actions: {
    async clearCache() {
      this.clearingCache = true

      const userStore = useUserStore()
      const dataStore = useDataStore()
      const filtersStore = useFiltersStore()

      await userStore.clearCache()
      await dataStore.clearCache()
      await filtersStore.clearCache()

      this.clearingCache = false

      toast.success('Cache Cleared')
    },
    setOpenSidebarMenu(slug) {
      if (this.openSidebarMenus.includes(slug)) {
        this.openSidebarMenus = this.openSidebarMenus.filter((item) => item !== slug)
        return
      }

      if (this.openSidebarMenus === slug) {
        this.openSidebarMenus = []
        return
      }

      this.openSidebarMenus.push(slug)
    }
  },
  persist: {
    key: `${import.meta.env.VITE_STORE_PREFIX}-global`,
  },
})
