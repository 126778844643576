export default [
  {
    path: 'competitions',
    children: [
      {
        name: 'competitions',
        path: '',
        component: () => import('./CompetitionsList.vue'),
        meta: { title: 'Competitions', guard: false }
      },
      {
        name: 'create-competition',
        path: 'create',
        component: () => import('./CompetitionForm.vue'),
        meta: { title: 'New Competition' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-competition',
        path: ':id/edit',
        component: () => import('./CompetitionForm.vue'),
        meta: { title: 'Edit Competition' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-competition',
        path: ':id/view',
        component: () => import('./CompetitionView.vue'),
        meta: {
          title: 'View Competition'
        },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'order-competitions',
        path: 'ordering',
        component: () => import('./CompetitionOrdering.vue'),
        meta: { title: 'Order Competitions' }
      }
    ]
  },
]
