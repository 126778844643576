export default [
  {
    path: 'forms',
    children: [
      {
        name: 'forms',
        path: '',
        component: () => import('./FormsList.vue'),
        meta: { title: 'Forms', guard: false }
      },
      {
        name: 'new-form-submission',
        path: ':slug/new',
        component: () => import('./FormForm.vue'),
        meta: {
          title: 'New Form Submission'
        },
        props: (route) => ({
          slug: route.params.slug,
          type: 'New'
        })
      },
      {
        name: 'view-form',
        path: ':id/view',
        component: () => import('./FormView.vue'),
        meta: {
          title: 'View Form'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
