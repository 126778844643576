export default [
  {
    path: 'coaches',
    children: [
      {
        name: 'coaches',
        path: '',
        component: () => import('./CoachesList.vue'),
        meta: { title: 'Coaches', guard: false }
      },
      {
        name: 'create-coach',
        path: 'create',
        component: () => import('./CoachForm.vue'),
        meta: { title: 'New Coach' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-coach',
        path: ':id/edit',
        component: () => import('./CoachForm.vue'),
        meta: { title: 'Edit Coach' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-coach',
        path: ':id/view',
        component: () => import('./CoachView.vue'),
        meta: {
          title: 'View Coach'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
