export default [
  {
    path: 'sections',
    children: [
      {
        name: 'sections',
        path: '',
        component: () => import('./SectionsList.vue'),
        meta: {
          title: 'Sections'
        }
      },
      {
        name: 'create-section',
        path: 'create',
        component: () => import('./SectionForm.vue'),
        meta: {
          title: 'New Section'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-section',
        path: ':id/edit',
        component: () => import('./SectionForm.vue'),
        meta: {
          title: 'Edit Section'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-section',
        path: ':id/view',
        component: () => import('./SectionView.vue'),
        meta: {
          title: 'View Section'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
