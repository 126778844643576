export default [
  {
    path: 'learning-portal',
    children: [
      {
        name: 'learning-portal-courses',
        path: '',
        component: () => import('./LearningPortalCourses.vue'),
        meta: {
          title: 'Courses - Learning Portal',
        },
      },
      {
        name: 'learning-portal-course',
        path: ':id/modules',
        component: () => import('./LearningPortalCourse.vue'),
        meta: {
          title: 'Modules - Learning Portal',
        },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'learning-portal-module',
        path: ':courseId/modules/:id',
        component: () => import('./LearningPortalModule.vue'),
        meta: {
          title: 'Module - Learning Portal',
        },
        props: (route) => ({
          courseId: route.params.courseId,
          id: route.params.id
        })
      },
    ]
  },
]
