import clubsRoutes from './clubs/routes'
import competitionsRoutes from './competitions/routes'
import roundsRoutes from './rounds/routes'
import seasonsRoutes from './seasons/routes'
import venuesRoutes from './venues/routes'
import honoursRoutes from './honours/routes'

export default [
  {
    path: 'management',
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      ...clubsRoutes,
      ...competitionsRoutes,
      ...honoursRoutes,
      ...roundsRoutes,
      ...seasonsRoutes,
      ...venuesRoutes,
    ]
  },
]
