export default [
  {
    path: 'permissions',
    children: [
      {
        name: 'permissions',
        path: '',
        component: () => import('./PermissionsList.vue'),
        meta: { title: 'Permissions', guard: false }
      },
      {
        name: 'create-permission',
        path: 'create',
        component: () => import('./PermissionForm.vue'),
        meta: { title: 'New Permission' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-permission',
        path: ':id/edit',
        component: () => import('./PermissionForm.vue'),
        meta: { title: 'Edit Permission' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-permission',
        path: ':id/view',
        component: () => import('./PermissionView.vue'),
        meta: {
          title: 'View Permission'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
