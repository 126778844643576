import ContractTemplateRoutes from './templates/routes'

export default [
  {
    path: 'contracts',
    children: [
      {
        name: 'contracts',
        path: '',
        component: () => import('./ContractsList.vue'),
        meta: { title: 'Contracts', guard: false }
      },
      {
        name: 'create-contract',
        path: 'create',
        component: () => import('./ContractForm.vue'),
        meta: { title: 'New Contract' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-contract',
        path: ':id/edit',
        component: () => import('./ContractForm.vue'),
        meta: { title: 'Edit Contract' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-contract',
        path: ':id/view',
        component: () => import('./ContractView.vue'),
        meta: {
          title: 'View Contract'
        },
        props: (route) => ({
          id: route.params.id
        })
      },
      ...ContractTemplateRoutes,
    ]
  },
]
