export default [
  {
    path: 'tribunal-records',
    children: [
      {
        name: 'tribunal-records',
        path: '',
        component: () => import('./TribunalList.vue'),
        meta: { title: 'Tribunal', guard: false }
      },
      {
        name: 'create-tribunal-record',
        path: 'create',
        component: () => import('./TribunalForm.vue'),
        meta: { title: 'New Tribunal' },
        props: {
          type: 'New',
        }
      },
      {
        name: 'create-noi-tribunal-record',
        path: 'create-noi',
        component: () => import('./TribunalForm.vue'),
        meta: { title: 'New Tribunal' },
        props: {
          type: 'New',
          reportAvailable: false
        }
      },
      {
        name: 'edit-tribunal-record',
        path: ':id/edit',
        component: () => import('./TribunalForm.vue'),
        meta: { title: 'Edit Tribunal' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-tribunal-record',
        path: ':id/view',
        component: () => import('./TribunalView.vue'),
        meta: {
          title: 'View Tribunal'
        },
        props: (route) => ({
          id: route.params.id
        })
      },
      {
        name: 'create-tribunal-record-statement',
        path: ':tribunalReportId/statements/create',
        component: () => import('./TribunalStatementForm.vue'),
        meta: { title: 'New Victim Impact Report' },
        props: (route) => ({
          type: 'New',
          tribunalReportId: route.params.tribunalReportId
        })
      },
      {
        name: 'edit-tribunal-record-statement',
        path: ':tribunalReportId/statements/:id/edit',
        component: () => import('./TribunalStatementForm.vue'),
        meta: { title: 'Edit Victim Impact Report' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id,
          tribunalReportId: route.params.tribunalReportId
        })
      },
      {
        name: 'edit-tribunal-record-assessment',
        path: ':id/assessment',
        component: () => import('./TribunalAssessmentForm.vue'),
        meta: { title: 'Edit Tribunal Record Assessment' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id,
        })
      },
    ]
  },
]
