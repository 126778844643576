export default [
  {
    path: 'injuries',
    children: [
      {
        name: 'injuries',
        path: '',
        component: () => import('./InjuriesList.vue'),
        meta: { title: 'Injury', guard: false }
      },
      {
        name: 'create-injury',
        path: 'create',
        component: () => import('./InjuryForm.vue'),
        meta: { title: 'New Injury' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-injury',
        path: ':id/edit',
        component: () => import('./InjuryForm.vue'),
        meta: { title: 'Edit Injury' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
    ]
  },
]
