export default [
  {
    path: 'attendance',
    children: [
      {
        name: 'attendance',
        path: '',
        component: () => import('./AttendanceList.vue'),
        meta: { title: 'Attendance', guard: false }
      },
      {
        name: 'create-attendance',
        path: 'create',
        component: () => import('./AttendanceForm.vue'),
        meta: { title: 'New Attendance' },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-attendance',
        path: ':id/edit',
        component: () => import('./AttendanceForm.vue'),
        meta: { title: 'Edit Attendance' },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
      {
        name: 'view-attendance',
        path: ':id/view',
        component: () => import('./AttendanceView.vue'),
        meta: {
          title: 'View Attendance'
        },
        props: (route) => ({
          id: route.params.id
        })
      }
    ]
  },
]
