import { defineStore } from 'pinia'
import { useApi } from '../composables/useApi'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      token: null,
      user: null,
      tenant: null,
      club: null,
      primaryUser: {},
      impersonating: false,
      uuid: null,
    }
  },
  getters: {
    menu() {
      return this.user?.menu ? this.user.menu : []
    },
    groups() {
      return this.user?.groups ? this.user.groups : []
    },
    permissions() {
      return this.user?.permissions ? this.user.permissions : []
    },
    tenants() {
      return this.user?.tenants ? this.user.tenants : []
    },
    currentTenant() {
      return this.tenants.find((tenant) => tenant.id === this.tenant)
    },
    isSuperAdmin() {
      return this.user?.nimdarepus
    },
    hasToken() {
      return !!this.token
    },
    isImpersonating() {
      return this.impersonating
    },
    clubs() {
      return this.user?.clubs ? this.user.clubs : []
    },
    currentClub() {
      return this.clubs.find((club) => club.id === this.club)
    },
    pointsEnabled() {
      return this.currentTenant?.settings?.points_enabled === true
    }
  },
  actions: {
    setUser(data) {
      this.token = data.token
      this.user = data.user

      if (data.user?.tenants?.length === 1 && !this.tenant) {
        this.tenant = data.user.tenants[0].id
      }

      if (data.user?.clubs?.length === 1 && !this.club) {
        this.club = data.user.clubs[0].id
      }
    },
    async impersonateUser(id) {
      const { api } = useApi()

      const { data } = await api.get(`/users/${id}/impersonate`)

      await this.setImpersonatedUser(data)
    },
    async setImpersonatedUser(data) {
      this.club = null

      this.setPrimaryUser()
      this.setUser(data)
      this.impersonating = true
      await this.refreshUser()
    },
    setPrimaryUser() {
      this.primaryUser = {
        token: this.token,
        user: this.user,
        tenant: this.tenant,
        club: this.club,
      }
    },
    restorePrimaryUser() {
      this.token = this.primaryUser.token
      this.user = this.primaryUser.user
      this.tenant = this.primaryUser.tenant
      this.club = this.primaryUser.club
      this.impersonating = false

      this.primaryUser = {}
    },
    logout() {
      this.token = null
      this.user = null
    },
    async clearCache() {
      this.user.tenants = []
      this.user.permissions = []
      this.user.groups = []
      this.user.sections = []
      this.user.menu = []

      await this.refreshUser()
    },
    async refreshUser() {
      const { api } = useApi()

      try {
        const response = await api.get('/me')

        this.user = response.data
      } catch (e) {
        throw new Error(e)
      }
    },
    async authenticate(payload) {
      const { api } = useApi()

      try {
        const response = await api.post('/authenticate', payload)

        this.setUser(response.data)
      } catch (e) {
        throw new Error(e)
      }
    },
    async ssoProviderRedirectUrl(provider) {
      const { api } = useApi()

      try {
        const response = await api.get(`/auth/${provider}/redirect`)

        return response.data.redirect_url
      } catch (e) {
        throw new Error(e)
      }
    },
    async forgotPassword(payload) {
      const { api } = useApi()

      try {
        await api.post('/forgot-password', payload)
      } catch (e) {
        throw new Error(e)
      }
    },
    async resetPassword(payload) {
      const { api } = useApi()

      try {
        await api.post('/reset-password', payload)
      } catch (e) {
        throw new Error(e)
      }
    },
    async passkeyAuthenticateOptions(payload) {
      const { api } = useApi()

      if (!this.uuid) {
        this.uuid = await crypto.randomUUID()
      }

      try {
        const response = await api.get('/passkeys/authenticate', {
          params: {
            uuid: this.uuid,
            ...payload,
          },
        })

        return response.data
      } catch (e) {
        throw new Error(e)
      }
    },
    async passkeyAuthenticate(payload) {
      const { api } = useApi()

      try {
        const response = await api.post('/passkeys/authenticate', payload)

        this.setUser(response.data)
      } catch (e) {
        throw new Error(e)
      }
    },
    hasGroup(group) {
      return !!this.groups.find((g) => g.name === group)
    },
    hasPermission(permission) {
      return !!this.permissions.find((p) => p.route === permission)
    },
    hasClub(club) {
      return !!this.clubs.find((c) => c.id === club)
    }
  },
  persist: {
    key: `${import.meta.env.VITE_STORE_PREFIX}-user`,
  },
})
