<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script setup>
  import { onBeforeMount } from 'vue'
  import { useGlobalStore } from '@/stores/global.js'
  import { useDataStore } from '@/stores/data.js'
  import { useUserStore } from '@/stores/user.js'

  const globalStore = useGlobalStore()
  const dataStore = useDataStore()
  const userStore = useUserStore()

  onBeforeMount(() => {
    globalStore.showPageLoader = false

    if (userStore.hasToken) {
      dataStore.getClubs()
      dataStore.getSeasons()
      dataStore.getRounds()
      dataStore.getCompetitions()
    }
  })
</script>
