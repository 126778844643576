import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue"

import 'element-plus/theme-chalk/base.css'
import 'element-plus/theme-chalk/el-date-picker.css'
import './css/daterangepicker.custom.css'
import './css/ckeditor.custom.css'

import '@formkit/themes/genesis'
import '@formkit/pro/genesis'
import './css/index.css'
import './css/formkit.custom.css'
import './css/jsoneditor.custom.css'
import 'vue3-toastify/dist/index.css'
import 'video.js/dist/video-js.css'

import config from '../formkit.config.js'

import { plugin, defaultConfig } from '@formkit/vue'
import Vue3Toastify from 'vue3-toastify'
import vClickOutside from 'click-outside-vue3'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

if (!import.meta.env.DEV) {
  Sentry.init({
    app,
    dsn: 'https://c959d5784d0cea0cd4b17c5c071fdf50@o1127129.ingest.sentry.io/4506838644097024',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/sportix\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(router)
app.use(pinia)
app.use(plugin, defaultConfig(config))
app.use(Vue3Toastify, {
  autoClose: 3000,
  theme: 'colored',
  position: 'bottom-right',
  limit: 5,
  clearOnUrlChange: false,
})
app.use(vClickOutside)

app.config.performance = true

app.mount('#app')
