export default [
  {
    path: 'tools',
    children: [
      {
        path: '',
        redirect: '/dashboard'
      },
      {
        name: 'stats-check',
        path: 'stats-check',
        component: () => import('@/views/tools/stats-check/StatsCheckForm.vue'),
        meta: { title: 'Stats Check' },
        props: {
          type: 'Edit'
        }
      },
    ]
  },
]
