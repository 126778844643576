export default [
  {
    path: 'voting',
    children: [
      {
        name: 'voting',
        path: '',
        component: () => import('./VotingList.vue'),
        meta: { title: 'Matches', guard: false }
      },
      {
        name: 'create-votes',
        path: ':id/create',
        component: () => import('./VotingForm.vue'),
        meta: { title: 'Create Votes' },
        props: (route) => ({
          type: 'New',
          id: route.params.id
        })
      },
    ]
  },
]
