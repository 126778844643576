export default [
  {
    path: 'publications',
    children: [
      {
        name: 'publications',
        path: '',
        component: () => import('./PublicationList.vue'),
        meta: { title: 'Publications' }
      },
      {
        name: 'create-publication',
        path: 'create',
        component: () => import('./PublicationForm.vue'),
        meta: {
          title: 'New Publication'
        },
        props: {
          type: 'New'
        }
      },
      {
        name: 'edit-publication',
        path: ':id/edit',
        component: () => import('./PublicationForm.vue'),
        meta: {
          title: 'Edit Publication'
        },
        props: (route) => ({
          type: 'Edit',
          id: route.params.id
        })
      },
    ]
  },
]
